/**
 * @generated SignedSource<<869086d3029a5ae0ad641e4c2b13cf5f>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Query } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type CurrentUserQuery$variables = {};
export type CurrentUserQuery$data = {
  readonly visitor: {
    readonly featureFlags: ContraAPIScalars.JSON | null;
    readonly id: string;
    readonly impersonator: {
      readonly id: string;
    } | null;
    readonly sessionId: string;
    readonly traits: ContraAPIScalars.JSON | null;
    readonly userAccount: {
      readonly " $fragmentSpreads": FragmentRefs<"CurrentUserContext_userAccount">;
    } | null;
  };
};
export type CurrentUserQuery = {
  response: CurrentUserQuery$data;
  variables: CurrentUserQuery$variables;
};

const node: ConcreteRequest = (function(){
var v0 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v1 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "featureFlags",
  "storageKey": null
},
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "sessionId",
  "storageKey": null
},
v3 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "traits",
  "storageKey": null
},
v4 = {
  "alias": null,
  "args": null,
  "concreteType": "UserAccount",
  "kind": "LinkedField",
  "name": "impersonator",
  "plural": false,
  "selections": [
    (v0/*: any*/)
  ],
  "storageKey": null
},
v5 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "count",
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": [],
    "kind": "Fragment",
    "metadata": null,
    "name": "CurrentUserQuery",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "Visitor",
        "kind": "LinkedField",
        "name": "visitor",
        "plural": false,
        "selections": [
          (v0/*: any*/),
          (v1/*: any*/),
          (v2/*: any*/),
          (v3/*: any*/),
          (v4/*: any*/),
          {
            "alias": null,
            "args": null,
            "concreteType": "UserAccount",
            "kind": "LinkedField",
            "name": "userAccount",
            "plural": false,
            "selections": [
              {
                "args": null,
                "kind": "FragmentSpread",
                "name": "CurrentUserContext_userAccount"
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [],
    "kind": "Operation",
    "name": "CurrentUserQuery",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "Visitor",
        "kind": "LinkedField",
        "name": "visitor",
        "plural": false,
        "selections": [
          (v0/*: any*/),
          (v1/*: any*/),
          (v2/*: any*/),
          (v3/*: any*/),
          (v4/*: any*/),
          {
            "alias": null,
            "args": null,
            "concreteType": "UserAccount",
            "kind": "LinkedField",
            "name": "userAccount",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "analyticsUserId",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "onboardingType",
                "storageKey": null
              },
              (v0/*: any*/),
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "isTestAccount",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "concreteType": "CustomDomainConnection",
                "kind": "LinkedField",
                "name": "customDomains",
                "plural": false,
                "selections": [
                  (v5/*: any*/),
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "CustomDomainEdge",
                    "kind": "LinkedField",
                    "name": "edges",
                    "plural": true,
                    "selections": [
                      {
                        "alias": null,
                        "args": null,
                        "concreteType": "CustomDomain",
                        "kind": "LinkedField",
                        "name": "node",
                        "plural": false,
                        "selections": [
                          {
                            "alias": null,
                            "args": null,
                            "kind": "ScalarField",
                            "name": "domain",
                            "storageKey": null
                          },
                          {
                            "alias": null,
                            "args": null,
                            "kind": "ScalarField",
                            "name": "status",
                            "storageKey": null
                          },
                          (v0/*: any*/)
                        ],
                        "storageKey": null
                      }
                    ],
                    "storageKey": null
                  }
                ],
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "emailAddress",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "concreteType": "UserProfile",
                "kind": "LinkedField",
                "name": "profile",
                "plural": false,
                "selections": [
                  (v0/*: any*/),
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "ContraImage",
                    "kind": "LinkedField",
                    "name": "avatarImage",
                    "plural": false,
                    "selections": [
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "uid",
                        "storageKey": null
                      },
                      (v0/*: any*/)
                    ],
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "hasActivePortfolioSubscription",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "topIndependentStatus",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "isFramerExpert",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "generalInquiryCtaIsVisible",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "IndependentPortfolioTemplateTheme",
                    "kind": "LinkedField",
                    "name": "independentPortfolioTemplateTheme",
                    "plural": false,
                    "selections": [
                      (v0/*: any*/),
                      {
                        "alias": null,
                        "args": null,
                        "concreteType": "IndependentPortfolioTemplate",
                        "kind": "LinkedField",
                        "name": "template",
                        "plural": false,
                        "selections": [
                          {
                            "alias": null,
                            "args": null,
                            "kind": "ScalarField",
                            "name": "displayName",
                            "storageKey": null
                          },
                          (v0/*: any*/)
                        ],
                        "storageKey": null
                      }
                    ],
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "WorkPreferences",
                    "kind": "LinkedField",
                    "name": "workPreferences",
                    "plural": false,
                    "selections": [
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "isCurrentlyAvailable",
                        "storageKey": null
                      }
                    ],
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "ReceivedTestimonialConnection",
                    "kind": "LinkedField",
                    "name": "receivedTestimonials",
                    "plural": false,
                    "selections": [
                      (v5/*: any*/)
                    ],
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "primaryProfessionalCategory",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "firstName",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "lastName",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "title",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "displayUsername",
                    "storageKey": null
                  }
                ],
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "userGroups",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "userFeatures",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "concreteType": "WaitListConnection",
                "kind": "LinkedField",
                "name": "waitLists",
                "plural": false,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "WaitListEdge",
                    "kind": "LinkedField",
                    "name": "edges",
                    "plural": true,
                    "selections": [
                      {
                        "alias": null,
                        "args": null,
                        "concreteType": "WaitList",
                        "kind": "LinkedField",
                        "name": "node",
                        "plural": false,
                        "selections": [
                          (v0/*: any*/),
                          {
                            "alias": null,
                            "args": null,
                            "kind": "ScalarField",
                            "name": "nid",
                            "storageKey": null
                          }
                        ],
                        "storageKey": null
                      }
                    ],
                    "storageKey": null
                  }
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "0c1708a2f8a80fd343cea70097860488",
    "id": null,
    "metadata": {},
    "name": "CurrentUserQuery",
    "operationKind": "query",
    "text": "query CurrentUserQuery {\n  visitor {\n    id\n    featureFlags\n    sessionId\n    traits\n    impersonator {\n      id\n    }\n    userAccount {\n      ...CurrentUserContext_userAccount\n      id\n    }\n  }\n}\n\nfragment CurrentUserContext_userAccount on UserAccount {\n  analyticsUserId\n  onboardingType\n  id\n  isTestAccount\n  customDomains {\n    count\n    edges {\n      node {\n        domain\n        status\n        id\n      }\n    }\n  }\n  emailAddress\n  profile {\n    id\n    avatarImage {\n      uid\n      id\n    }\n    hasActivePortfolioSubscription\n    topIndependentStatus\n    isFramerExpert\n    generalInquiryCtaIsVisible\n    independentPortfolioTemplateTheme {\n      id\n      template {\n        displayName\n        id\n      }\n    }\n    workPreferences {\n      isCurrentlyAvailable\n    }\n    receivedTestimonials {\n      count\n    }\n    primaryProfessionalCategory\n    firstName\n    lastName\n    title\n    displayUsername\n  }\n  userGroups\n  userFeatures\n  waitLists {\n    edges {\n      node {\n        id\n        nid\n      }\n    }\n  }\n}\n"
  }
};
})();

(node as any).hash = "d27c9f391e4f906c800ec2aebc752372";

export default node;
