import {
  CurrentUserContext,
  type CurrentUserContextValue,
} from '@/contexts/CurrentUserContext.js';
import { useContext } from 'react';

export const useCurrentUser = (): CurrentUserContextValue | null => {
  const currentUser = useContext(CurrentUserContext);

  return currentUser;
};
