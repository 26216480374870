import { useRouter } from 'next/router';

export function useSearchParameter(
  parameterName: string,
  constraint?: 'string',
): string | null;
export function useSearchParameter(
  parameterName: string,
  constraint?: 'boolean',
): boolean;

// eslint-disable-next-line func-style
export function useSearchParameter(
  parameterName: string,
  constraint: 'boolean' | 'string' = 'string',
): boolean | string | null {
  const { query } = useRouter();
  const value = query[parameterName];

  if (constraint === 'string' && typeof value === 'string' && value) {
    return value;
  }

  if (constraint === 'boolean') {
    if (value === 'true') {
      return true;
    }

    return false;
  }

  return null;
}
