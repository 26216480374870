import { graphql } from 'relay-runtime';

const CurrentUser = graphql`
  query CurrentUserQuery {
    visitor {
      id
      featureFlags
      sessionId
      traits
      impersonator {
        id
      }
      userAccount {
        ...CurrentUserContext_userAccount
      }
    }
  }
`;

export default CurrentUser;
