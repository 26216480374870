import { type NextRouter } from 'next/router';

export const shallowRemoveQueryParameters = (
  router: NextRouter,
  parametersToRemove: string[] = [],
) => {
  // If none of the requested parameters are in the query, do nothing
  if (!parametersToRemove.some((parameter) => parameter in router.query)) {
    return;
  }

  const hash = /#\w*/u.exec(router.asPath)?.[0] ?? undefined;

  // eslint-disable-next-line @typescript-eslint/no-dynamic-delete
  for (const parameter of parametersToRemove) delete router.query[parameter];

  void router.replace(
    {
      hash,
      pathname: router.pathname,
      query: router.query,
    },
    undefined,
    /**
     * Do not refresh the page when the query params are removed
     */
    { shallow: true },
  );
};
