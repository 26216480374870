import {
  type CurrentUserContext_userAccount$data,
  type CurrentUserContext_userAccount$key,
  UserFeature,
} from '@/__generated__/relay/CurrentUserContext_userAccount.graphql.js';
import { type CurrentUserContextShortCodeMutation } from '@/__generated__/relay/CurrentUserContextShortCodeMutation.graphql.js';
import { type CurrentUserQuery } from '@/__generated__/relay/CurrentUserQuery.graphql.js';
import CURRENT_USER_QUERY from '@/documents/queries/CurrentUser.js';
import { useSearchParameter } from '@/hooks/useSearchParameter.js';
import { logger } from '@/services/logger.js';
import { type FeatureFlags } from '@/utilities/getDefaultFeatureFlags.js';
import { normalizeUsername } from '@/utilities/normalizeUsername.js';
import { shallowRemoveQueryParameters } from '@/utilities/shallowRemoveQueryParameters.js';
import { captureException } from '@sentry/nextjs';
import { useRouter } from 'next/router';
import {
  createContext,
  type ReactNode,
  useEffect,
  useMemo,
  useRef,
} from 'react';
import {
  graphql,
  // eslint-disable-next-line canonical/no-restricted-imports
  useFragment,
  useLazyLoadQuery,
  useMutation,
} from 'react-relay';

const log = logger.child({
  namespace: 'CurrentUserContext',
});

type JsonObject<T = {}> = T & { [Key in string]?: JsonValue<T> };
type JsonValue<T> =
  | Array<JsonValue<T>>
  | JsonObject<T>
  | boolean
  | number
  | string
  | null;

type UserAccount = CurrentUserContext_userAccount$data;

type AnalyticsTraits = {
  analyticsUserId: string | null;
  areAnalyticsEnabled: boolean;
  featureFlags: FeatureFlags | null;
  sessionId: string | null;
};

type WaitListNid = string;

type AdditionalUserProperties = {
  canSubscribe: boolean;
  currentPublishedThemeId: string | null;
  hasPublishedPortfolio: boolean;
  isOnboardingCompleted: boolean;
  isProfileCompleted: boolean;
  userFeatures: readonly UserFeature[];
  waitLists: WaitListNid[];
};

export type CurrentUserContextValue = AdditionalUserProperties &
  AnalyticsTraits & { userAccount: UserAccount | null };

export const CurrentUserContext = createContext<CurrentUserContextValue | null>(
  null,
);

export const CurrentUserProvider = ({
  children,
}: {
  readonly children: ReactNode;
}) => {
  const router = useRouter();
  const hasRunConfirmShortCode = useRef(false);
  const data = useLazyLoadQuery<CurrentUserQuery>(CURRENT_USER_QUERY, {});
  const urlShortCode = useSearchParameter('token', 'string');
  const urlEmailAddress = useSearchParameter('emailAddress', 'string');
  const isTemplatesPage = router.pathname.includes('/templates');
  const isThemesPage = router.pathname.startsWith('/themes');

  const [confirmShortCode, confirmShortCodeLoading] =
    useMutation<CurrentUserContextShortCodeMutation>(graphql`
      mutation CurrentUserContextShortCodeMutation(
        $input: ConfirmShortCodeInput!
      ) {
        confirmShortCode(input: $input) {
          errors {
            __typename
            message
          }

          result
          visitor {
            userAccount {
              ...CurrentUserContext_userAccount
              profile {
                id
                visitorCanEdit
              }
            }
          }
        }
      }
    `);

  useEffect(() => {
    if (
      urlShortCode &&
      urlEmailAddress &&
      !data.visitor.userAccount &&
      !confirmShortCodeLoading &&
      !hasRunConfirmShortCode.current
    ) {
      hasRunConfirmShortCode.current = true;
      confirmShortCode({
        onCompleted: (response) => {
          const errors = response.confirmShortCode.errors;

          if (errors?.length) {
            log.error('Error confirming short code');
            shallowRemoveQueryParameters(router, ['token', 'emailAddress']);
            return;
          }

          if (typeof window !== 'undefined') {
            // Remove the query parameters from the URL and reload
            shallowRemoveQueryParameters(router, ['token', 'emailAddress']);
          }
        },
        onError: (error) => {
          captureException(error);
          shallowRemoveQueryParameters(router, ['token', 'emailAddress']);
        },
        variables: {
          input: {
            code: urlShortCode,
            emailAddress: urlEmailAddress,
          },
        },
      });
    }

    if (
      (urlShortCode || urlEmailAddress) &&
      data.visitor.userAccount &&
      !confirmShortCodeLoading
    ) {
      // ensure they're removed if the user somehow ends up on the app
      // with those parameters while logged in
      shallowRemoveQueryParameters(router, ['token', 'emailAddress']);
    }
  }, [
    confirmShortCode,
    confirmShortCodeLoading,
    data,
    isTemplatesPage,
    isThemesPage,
    router,
    urlEmailAddress,
    urlShortCode,
  ]);

  const userAccount = useFragment<CurrentUserContext_userAccount$key>(
    graphql`
      fragment CurrentUserContext_userAccount on UserAccount {
        analyticsUserId
        onboardingType
        id
        isTestAccount
        customDomains {
          count
          edges {
            node {
              domain
              status
            }
          }
        }
        emailAddress
        profile {
          id
          avatarImage {
            uid
          }
          hasActivePortfolioSubscription
          topIndependentStatus
          isFramerExpert
          generalInquiryCtaIsVisible
          independentPortfolioTemplateTheme {
            id
            template {
              displayName
              id
            }
          }
          workPreferences {
            isCurrentlyAvailable
          }
          receivedTestimonials {
            count
          }
          primaryProfessionalCategory
          firstName
          lastName
          title
          displayUsername
        }
        userGroups
        userFeatures
        waitLists {
          edges {
            node {
              id
              nid
            }
          }
        }
      }
    `,
    data.visitor.userAccount,
  );

  const value = useMemo(() => {
    const visitor = data?.visitor;
    const userGroups = userAccount?.userGroups ?? [];
    const userFeatures = userAccount?.userFeatures ?? [];

    const independentPortfolioTemplateTheme =
      userAccount?.profile?.independentPortfolioTemplateTheme;
    const hasPublishedPortfolio = Boolean(independentPortfolioTemplateTheme);
    const currentUser = {
      analyticsUserId: userAccount?.analyticsUserId ?? null,
      areAnalyticsEnabled: Boolean(!data.visitor.impersonator?.id),
      canSubscribe: userFeatures.includes('SUBSCRIBE_TO_PORTFOLIOS'),
      currentPublishedThemeId: independentPortfolioTemplateTheme?.id ?? null,
      featureFlags: visitor.featureFlags as FeatureFlags,
      hasPublishedPortfolio,
      id: visitor.id,
      isOnboardingCompleted: userGroups.includes(
        'CONTRACTOR_ONBOARDING_COMPLETE',
      ),
      isProfileCompleted:
        userGroups.includes('CONTRACTOR_ONBOARDING_COMPLETE') &&
        userGroups.includes('CONTRACTOR_PROFILE_COMPLETE'),
      sessionId: visitor.sessionId,
      traits: visitor.traits,
      userAccount: userAccount
        ? {
            ...userAccount,
            profile: {
              ...userAccount.profile,
              displayUsername: normalizeUsername(
                userAccount.profile.displayUsername,
              ),
            },
          }
        : null,
      userFeatures,
      waitLists: userAccount?.waitLists.edges.map(({ node }) => node.nid) ?? [],
    };

    log.debug(
      {
        analyticsUserId: currentUser.analyticsUserId,
        areAnalyticsEnabled: currentUser.areAnalyticsEnabled,
        featureFlags: currentUser.featureFlags,
        sessionId: currentUser.sessionId,
      },
      'Loaded CurrentUser',
    );

    return currentUser;
  }, [data.visitor, userAccount]);

  return (
    <CurrentUserContext.Provider value={value}>
      {children}
    </CurrentUserContext.Provider>
  );
};
